<template>
    <section class="pd15">
        <section v-if="isDrug">
            <div class="banner">
             <img :src="drug.attachUrl"/>
         </div>
        <div clss="detail" v-html="drug.instruction"></div>
        </section>
        <section v-else>
            <img style="" src='https://i2.hdfimg.com/ssi/image/niudefu/niudefu_emptypage.png'/>
        </section>
    </section>
</template>
<script>

import request from "../api/index";
export default {
  props: ['id'],
   data() {
    return {
      drug: {}
    };
  },
  computed:{
    isDrug(){
      return Object.keys(this.drug).length > 0;
    }
  },
  mounted() {
    const success = (res) => {
      console.log("res",res)
      this.drug = res.data;
    };
    request(
      `naskpackage/getcommoditydetail?id=${this.id}`,
      success
    );
  }
}
</script>
<style scoped>
.pd15{padding:15px;}
.banner {
    height: 250px;
    -webkit-background-size: 100vw 250px;
    -moz-background-size: 100vw 250px;
    -o-background-size: 100vw 250px;
    background-size: 100vw 250px;
    background-repeat: no-repeat;
    background-color: #f5f5f5;
}
.banner img{
    max-width: 90%;
    height: 250px;
    display: block;
    margin: 0 auto;
}
.detail{
    padding:0 15px;
}


.commodityinfo {
  background-color: #fff;
  padding: 15px 0 0px 15px;
  border-bottom: 5px solid #f8f8f8; }
  .commodityinfo .ci-name {
    font-size: 21px;
    color: #000; }
  .commodityinfo .ci-info {
    padding: 10px 0;
    border-bottom: 1px solid #E4E4E4; }
    .commodityinfo .ci-info .ci-info-title {
      font-size: 17px;
      color: #969696; }
    .commodityinfo .ci-info .ci-info-cont {
      font-size: 18px;
      color: #333333;
      white-space: pre-wrap;
      word-break: break-all; }
    .commodityinfo .ci-info .ci-info-cont-red {
      color: #FF4D52; }
    .commodityinfo .ci-info .cd-info-cont-grey {
      color: #969696;
      font-size: 15px; }
  .commodityinfo .ci-info:last-child {
    border-bottom: 0 none; }

/*  保存草稿的按钮 */
.preserve-warp {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  background: #F8F8F8;
  border-top: 1px solid #CCCCCC;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
          justify-content: space-around; }
  .preserve-warp .preserve-public {
    display: inline-block;
    height: 45px;
    line-height: 45px;
    text-align: center;
    font-size: 19px;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box; }
  .preserve-warp .preserve-draft {
    width: 37%;
    background: #FFFFFF;
    border: 1px solid #48AEFF;
    -webkit-border-radius: 3px;
       -moz-border-radius: 3px;
            border-radius: 3px;
    color: #1E8CE6; }
  .preserve-warp .preserve-draft-upshelve {
    width: 52%;
    background: #48AEFF;
    -webkit-border-radius: 3px;
       -moz-border-radius: 3px;
            border-radius: 3px;
    color: #fff; }
</style>
